/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, Action, State} from 'vuex-class'
import { VideoStories } from '@/store/videoStories/types';

const namespace: string = 'videoStories';

export default class VideoStoriesVideoChoicePage extends Vue {
	@State('videoStories') videoStories: VideoStories | undefined;
	@Action('fetchModelsVideo', {namespace}) fetchModelsVideo: any;
	@Getter('getVideoList', {namespace}) getVideoList: any;
	@Getter('getChosenModels', {namespace}) getChosenModels: any;
	@Mutation('setChosenVideo', {namespace}) setChosenVideo: any;

	selectedVideo(video: Object) {
		this.setChosenVideo(video);
		this.$router.push({ name: 'video-stories-video-chosen' }).then(r => r);
	}

	created() {
		this.fetchModelsVideo();
	}
}
